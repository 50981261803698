import React, { useEffect } from "react"
import { StaticImage } from "gatsby-plugin-image"
import AOS from 'aos';

import Seo from "../components/seo"
import Header from "../components/header"
import Footer from "../components/footer"


const RecursosMultiPage = () => {

  useEffect(() => {
	 AOS.init()
  }, []);


  return (
	<main>
	  <Seo
		title="Controle Diversas Lojas / Empresas em um só sistema 🏘"
		description="Sistema wEstoque integra suas lojas (empresas) e estoques em um ERP robusto e barato. Veja o estoque, vendas e financeiro de todas as lojas e operações de um só acesso. Teste agora!"
		keywords={[
			`multi empresas`,
			`multi lojas`,
			`multiplas lojas online`,
			`controle de locais de venda`,
			`multiplas empresas`,
			`multiplos estoques`,
			`controlar várias empresas`,
			`controle várias lojas`,
			`controle diversos estoques`,
			`controlar vários estoques`,
		]}

	  />

	  <Header />
 
	  <section style={{paddingTop: `100px`}} className="overflow-x-hidden">

		<div className="max-w-6xl mx-auto">

			<div className="py-8 md:py-15">
			
			<div className="grid gap-3 sm:gap-8">
				
							<div className="md:grid md:grid-cols-12 gap-3 items-center p-3">
								<div className="max-w-xl md:max-w-none md:w-full mx-auto md:col-span-5 lg:col-span-5 mb-8 md:mb-0 md:order-1 aos-init aos-animate hidden md:block -mt-10" data-aos="fade-in">

									<StaticImage
										src="../images/multifiliais-topo-westoque.png"
										alt="Controle várias empresas / lojas de um só sistema"
										placeholder="blurred"
										layout="fixed"
										quality={100}
										width={350}
										className="max-w-full mx-auto h-auto"
									/>
								</div>
								<div className="max-w-xl md:max-w-none md:w-full mx-auto md:col-span-7 lg:col-span-7 aos-init aos-animate items-center text-center" data-aos="fade-right">
									<div className="max-w-4xl mx-auto text-center pb-12 md:pb-16 items-center">
										<h1 className="text-center text-5xl font-bold" >
                                        Controle de Multi Filiais (Empresas)
										</h1>
										<br/>
										<p className="text-xl text-gray-700 mb-4">Nosso sistema é ideal para você que possui mais de uma empresa ou loja e que não quer nem pensar na possibilidade de ter que usar vários sistema para gerenciar o seu negócio.</p>
										<br/>
										<a href="/usar" className="text-md font-bold text-westoque-400 hover:text-westoque-500 hover:underline">Teste Gratuitamente &#8594;</a>
									</div>
								</div>
							</div>



							<div className="md:grid md:grid-cols-12 gap-3 items-center p-3 mt-28">
								<div className="max-w-xl md:max-w-none md:w-full mx-auto md:col-span-5 lg:col-span-5 mb-8 md:mb-0 aos-init aos-animate hidden md:block -mt-10" data-aos="fade-in">

									<StaticImage
											src="../images/recursos-multi-cnpj.png"
											alt="Varios CNPJs em um só sistema"
											placeholder="blurred"
											quality={100}
											className="w-full"
										/>
								</div>
								<div className="max-w-xl md:max-w-none md:w-full mx-auto md:col-span-7 lg:col-span-7 aos-init aos-animate items-center text-left align-top" data-aos="fade-right">
									<div className="relative px-4 -mt-16  ">
										<div className="">

											<h4 className="mt-1 text-2xl font-semibold text-westoque-400"><span className="md:hidden" role="img" aria-label="empresas emoji">🏘</span> Controle CNPJs diferentes no mesmo sistema</h4>

											<div className="mt-1 text-md md:text-xl">
											Você poderá cadastrar várias empresas com CNPJ diferentes e gerenciar os dados num único lugar, facilitando o dia-a-dia da sua empresa. Com isso, você gestor vai ter mais tempo para analisar as informações de cada filial e tomar decisões mais precisas.
											</div>
										</div>
									</div> 
								</div>
							</div>


							<div className="md:grid md:grid-cols-12 gap-3 items-center p-3 mt-10">
								<div className="max-w-xl md:max-w-none md:w-full mx-auto md:col-span-5 lg:col-span-5 mb-8 md:mb-0 md:order-1 aos-init aos-animate hidden md:block -mt-10" data-aos="fade-in">

										<StaticImage
											src="../images/recursos-navegue-todas-lojas.png"
											alt="Navegue por todas as lojas em tempo real"
											placeholder="blurred"
											quality={100}
											className="w-full max-w-md"
										/>
										
								</div>
								<div className="max-w-xl md:max-w-none md:w-full mx-auto md:col-span-7 lg:col-span-7 aos-init aos-animate items-center text-left" data-aos="fade-right">
									<div className="relative px-4 -mt-16  ">
										<div className="">

											<h4 className="mt-1 text-2xl font-bold text-westoque-400"><span className="md:hidden" role="img" aria-label="tempo emoji">⏳</span> Navegue por todas as lojas em tempo real</h4>

											<div className="mt-1 text-md md:text-xl">
											Com nosso ERP online é fácil alterar/trocar a navegação entre as lojas / empresas sem precisar sair e entrar novamente no sistema. Tudo intuitívo e rápido. Controle suas empresas de qualquer lugar que estiver.
											</div>
										</div>
									</div>
								</div>
							</div>


							<div className="md:grid md:grid-cols-12 gap-3 items-center p-3 mt-10">
								<div className="max-w-xl md:max-w-none md:w-full mx-auto md:col-span-5 lg:col-span-5 mb-8 md:mb-0 md:order-1 aos-init aos-animate hidden md:block -mt-10" data-aos="fade-in">
									<StaticImage
										src="../images/recursos-aumenta-frente-loja.png"
										alt="Aumente sua frente de loja"
										placeholder="blurred"
										quality={100}
										className="w-full max-w-md"
									/>
								</div>
								<div className="max-w-xl md:max-w-none md:w-full mx-auto md:col-span-7 lg:col-span-7 aos-init aos-animate items-center text-left md:order-1" data-aos="fade-right">
									<div className="relative px-4 -mt-16  ">
										<div className="">

											<h4 className="mt-1 text-2xl font-bold text-westoque-400"><span className="md:hidden" role="img" aria-label="ponto emoji">💈</span> Aumente sua frente de loja / estoque</h4>

											<div className="mt-1 text-md md:text-xl">
											Com os estoques / lojas integradas é possível fazer vendas e retirar produtos de outros localidades. Consulte também estoques de outras lojas ou depósitos e garanta maior número de vendas.
											</div>
										</div>
									</div>
								</div>
							</div>


							


							<div className="md:grid md:grid-cols-12 md:gap-6 items-center mt-36">
								<div className="max-w-xl md:max-w-none md:w-full mx-auto md:col-span-5 lg:col-span-6 mb-8 md:mb-0 md:order-1 aos-init aos-animate hidden md:block" data-aos="fade-in">
				  					<StaticImage
										src="../images/call-pdv-mercado.png"
										alt="Controle sua loja com o sistema wEstoque"
										placeholder="blurred"
										layout="fullWidth"
										quality={100}
									/>
								</div>
								<div className="max-w-xl md:max-w-none md:w-full mx-auto md:col-span-7 lg:col-span-6 aos-init aos-animate text-center" data-aos="fade-right">
									<div className="max-w-4xl mx-auto text-center pb-6 md:pb-8">
										<h1 className="text-center text-5xl font-bold mb-2 p-1" >
											<span className="underline decoration-westoque-400">Controle Financeiro</span> e de <span className="underline decoration-westoque-400">Vendas</span> fácil e eficiente para ajudar <span className="underline decoration-sky-500">seu negócio</span>.
										</h1> 
										<div className="text-xl text-gray-700 mb-2 w-full p-3">Deixe nossa solução ajudar você a se organizar. Com o <b className="text-2xl">w<span className="text-westoque-400">Estoque</span></b> ganha segurança nas tomadas de decisões!</div>
									</div>
									<a className="max-w-xs font-medium w-full inline-flex items-center justify-center border border-transparent px-4 py-2 my-2 rounded-md text-white bg-westoque-400 hover:bg-westoque-500 hover:transition-transform hover:scale-110 shadow-md shadow-westoque-400/50 transition duration-150 ease-in-out" href="/usar ">EXPERIMENTE GRÁTIS</a>
								</div>
							</div>


						</div>
					</div>
				</div>
			</section>

	  <br/><br/>
	  <Footer />


	</main>
  )
}

export default RecursosMultiPage
